
import React from "react"
import {parseToNumber, Products } from "../utils/product"
import ComboTemplate from "../components/Templates/ComboTemplate"
import { useParams } from "../hooks"

const ComboFlynow = () => {
  const {params} =useParams()

  const CUPOM = params.cupom || 'FLYN25'
  const data = {
    id: "combo_app_curso_produtividade",
    title: "PREMIUM VITALÍCIO + CURSO PRODUTIVIDADE",
    discontPercentage: parseToNumber(CUPOM) || 25,
    URL_CHECKOUT: "https://buy.stripe.com/eVag0L5m9f0x8Fi7sR?locale=pt-BR&prefilled_promo_code="+CUPOM,
    cta: "ADQUIRIR COMBO PROMOCIONAL",
    sections: [
      {
        section: "APLICATIVO (PLANO PREMIUM VITALÍCIO)",
        data: [Products.apps[0]]
      },
      {
        section: "CURSO DE PRODUTIVIDADE",
        data: [Products.cursos[0]]
      },
    ],
  }
  return <ComboTemplate  {...data} />
}
export default ComboFlynow